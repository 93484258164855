<template>
  <div
    class="data-card"
    :class="{ card: listType == 'card', list: listType == 'list' }"
    @click="jump"
  >
    <div class="cover">
      <img src="@/assets/image/mine/cyclic_cover.png" alt="exam" />
    </div>
    <div class="detail">
      <h5>
        <template v-if="listType == 'list'">
          <span class="status bg-gray" v-if="dataSource.myTimes === 0">
            {{ $t("CM_NoTakePartIn")
            }}<!-- 未参与 -->
          </span>
          <span
            class="status bg-green"
            v-else-if="dataSource.status === 8 || dataSource.status === 9"
          >
            {{ $t("Pub_Passed")
            }}<!-- 已通过 -->
          </span>
          <span class="status bg-red" v-else-if="dataSource.status === 2">
            {{ $t("XB_Unthread")
            }}<!-- 不通过 -->
          </span>
        </template>
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ dataSource.taskName }}
            </div>
          </template>
          {{ dataSource.taskName }}
        </a-popover>
      </h5>
      <div class="row mt12">
        {{ $t("LB_Exam_EnterCounts") }}：<span class="blue">{{
          dataSource.myTimes
        }}</span
        >/{{
          dataSource.examTimes == -2
            ? $t("exam.unlimited") // 不限次数
            : dataSource.examTimes == -1
            ? 1
            : dataSource.examTimes
        }}
        <!-- 参考次数 -->
      </div>
      <div class="row mt12" v-if="dataSource.taskType != 8">
        <span v-show="listType == 'list'">{{ $t("Pub_Lab_ExamTime") }}：</span
        ><img
          class="icon"
          v-show="listType == 'card'"
          src="@/assets/image/mine/time.png"
          alt="time"
        /><span class="fs12"
          >{{ dateFormat(dataSource.startTime) }} ～
          {{ dateFormat(dataSource.endTime) }}</span
        >
        <!-- 考试时间 -->
      </div>
      <div class="row space-between" v-show="listType == 'card'">
        <div class="left">
          <span v-if="dataSource.status == 1">
            {{ $t("Pub_WithoutApproval")
            }}<!-- 未批阅 -->
          </span>
          <span
            class="green"
            v-else-if="dataSource.status === 8 || dataSource.status === 9"
          >
            {{ dataSource.score }}{{ $t("CM_Points") }}
          </span>
          <span class="red" v-else-if="dataSource.status === 2">
            {{ dataSource.score }}{{ $t("CM_Points") }}
          </span>
          <span v-else-if="dataSource.myTimes == 0">
            {{ $t("exam.not_score")
            }}<!-- 未得分 -->
          </span>
        </div>
        <div class="right">
          <span v-if="dataSource.status == 1">
            {{ $t("XB_Exam_PendingApproval")
            }}<!-- 待批阅 -->
          </span>
          <span
            class="green"
            v-else-if="dataSource.status === 8 || dataSource.status === 9"
          >
            {{ $t("Pub_Passed")
            }}<!-- 已通过 -->
          </span>
          <span class="red" v-else-if="dataSource.status === 2">
            {{ $t("XB_Unthread")
            }}<!-- 不通过 -->
          </span>
          <span v-else-if="dataSource.myTimes == 0">
            {{ $t("CM_NoTakePartIn")
            }}<!-- 未参与 -->
          </span>
        </div>
      </div>
      <div class="row mt12" v-show="listType == 'list'">
        <span class="fs16 fw600" v-if="dataSource.status == 1">
          {{ $t("XB_Exam_PendingApproval")
          }}<!-- 待批阅 -->
        </span>
        <span v-else-if="dataSource.status === 8 || dataSource.status === 9">
          {{ $t("exam.exam_score") }}：<i class="fs16 fw600 green"
            >{{ dataSource.score }}{{ $t("CM_Points") }}</i
          >
          <!-- 考试得分 -->
        </span>
        <span v-else-if="dataSource.status === 2">
          {{ $t("exam.exam_score") }}：<i class="fs16 fw600 red"
            >{{ dataSource.score }}{{ $t("CM_Points") }}</i
          >
          <!-- 考试得分 -->
        </span>
        <span class="fs16 fw600" v-else-if="dataSource.myTimes == 0">
          {{ $t("exam.not_score")
          }}<!-- 未得分 -->
        </span>
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <div class="btn">
        {{ $t("view_detail") }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
export default {
  name: "CyclicCard",
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const router = useRouter();

    const jump = () => {
      if (props.newWindow) {
        window.open(
          `/exam/detail?ddtab=true&id=${props.dataSource.taskId}&did=${props.dataSource.detailId}`
        );
      } else {
        router.push({
          path: "/exam/detail",
          query: {
            id: props.dataSource.taskId,
            did: props.dataSource.detailId,
          },
        });
      }
    };

    return {
      dateFormat,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.data-card {
  &.card {
    .row.space-between {
      font-size: 16px !important;
      font-weight: 600;
      .mixinFlex(space-between; center);
      padding-top: 12px;
      border-top: 1px solid #f4f4f4;
    }
  }
}
</style>
